import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-81f9f4ea"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "px-5 question-wrap d-flex flex-column"
}
const _hoisted_2 = { style: {"clear":"both","flex":"1","overflow-y":"scroll"} }
const _hoisted_3 = {
  class: "my-3 d-flex",
  style: {}
}
const _hoisted_4 = { style: {"margin-right":"1em"} }
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = {
  key: 0,
  class: "main-q"
}
const _hoisted_7 = ["innerHTML"]
const _hoisted_8 = {
  key: 1,
  class: "sub-q"
}
const _hoisted_9 = ["innerHTML"]
const _hoisted_10 = {
  id: "footer",
  class: "mt-3"
}
const _hoisted_11 = { class: "d-flex mt-auto" }
const _hoisted_12 = { style: {"flex":"1"} }
const _hoisted_13 = { class: "" }
const _hoisted_14 = ["disabled"]
const _hoisted_15 = ["disabled"]
const _hoisted_16 = { class: "mes" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Timer = _resolveComponent("Timer")!

  return (_ctx.question != null)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, "問" + _toDisplayString(_ctx.$route.params.id), 1),
            (_ctx.question.question1 != '')
              ? (_openBlock(), _createElementBlock("p", {
                  key: 0,
                  innerHTML: _ctx.question.question1
                }, null, 8, _hoisted_5))
              : _createCommentVNode("", true)
          ]),
          (_ctx.question.question2 != '')
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                _createElementVNode("p", {
                  innerHTML: _ctx.question.question2
                }, null, 8, _hoisted_7)
              ]))
            : _createCommentVNode("", true),
          (_ctx.question.question3 != '')
            ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                _createElementVNode("p", {
                  innerHTML: _ctx.question.question3
                }, null, 8, _hoisted_9)
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", null, [
            (_ctx.question != undefined)
              ? (_openBlock(), _createBlock(_resolveDynamicComponent('Comp' + String(_ctx.question.answerType)), {
                  qIndex: Number(_ctx.$route.params.id) - 1,
                  no: _ctx.question.no,
                  questions: _ctx.data,
                  key: 'comp' + String(_ctx.question.id)
                }, null, 8, ["qIndex", "no", "questions"]))
              : _createCommentVNode("", true)
          ])
        ]),
        _createElementVNode("div", _hoisted_10, [
          _createElementVNode("div", _hoisted_11, [
            _createElementVNode("div", _hoisted_12, [
              _createVNode(_component_Timer, {
                qIndex: _ctx.$route.params.id,
                ref: "timer",
                onOverTime: _ctx.overTime
              }, null, 8, ["qIndex", "onOverTime"])
            ]),
            _createElementVNode("div", _hoisted_13, [
              _createElementVNode("button", {
                class: "btn btn-secondary mt-2",
                onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.next && _ctx.next(...args))),
                style: {"margin-right":"1em"},
                disabled: _ctx.buttonDisabled
              }, "次へ進む >>", 8, _hoisted_14),
              _createElementVNode("button", {
                class: "btn btn-secondary mt-2",
                onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.end && _ctx.end(...args))),
                disabled: _ctx.buttonDisabled
              }, "終了", 8, _hoisted_15)
            ])
          ]),
          _createElementVNode("div", _hoisted_16, "制限時間は" + _toDisplayString(_ctx.allMinutes) + "分です。", 1)
        ])
      ]))
    : _createCommentVNode("", true)
}