
export default [
    {
        "id": 1,
        "shikenId": 1,
        "parentNo": 1,
        "no": 1,
        "answerType": 1,
        "question1": "■表を見て次の問いに答えなさい。<br> <br> 【ある市の１年における行政区別人口動態】<br> <img src='/img/s0.png' class='c-img'/>",
        "question2": "Ｄ区でこの年に増加した人口は、Ｉ区でこの年に増加した人口のおよそ何倍か。最も近いものを以下の選択肢の中から１つ選びなさい。",
        "question3": "",
        "answer": "3",
        "score": 1,
        "choice1": "3.73倍",
        "choice2": "3.85倍",
        "choice3": "3.97倍",
        "choice4": "4.09倍",
        "choice5": "4.21倍",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 2,
        "shikenId": 1,
        "parentNo": 2,
        "no": 1,
        "answerType": 1,
        "question1": "■グラフを見て次の問いに答えなさい。<br> <br> 【ある企業における社員の部署ごとの年齢構成】<br> <img src='/img/s1.png' class='c-img'/>",
        "question2": "部署Ｃにおける20代の社員の数を１とすると、同部署の40代の社員の数はおよそいくつで表されるか。最も近いものを以下の選択肢の中から１つ選びなさい。",
        "question3": "",
        "answer": "2",
        "score": 1,
        "choice1": "2.5",
        "choice2": "3.1",
        "choice3": "3.7",
        "choice4": "4.3",
        "choice5": "4.9",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 3,
        "shikenId": 1,
        "parentNo": 3,
        "no": 1,
        "answerType": 1,
        "question1": "■グラフを見て次の問いに答えなさい。<br> <br> 【企業規模別・労働組合員数の構成比（2024年）】<br> <img src='/img/s2.png' class='c-img' style='width:500px;'/><br> 出典：政府統計の総合窓口(e-Stat)（https://www.e-stat.go.jp/）掲載資料を加工して作成",
        "question2": "300～999人規模の企業において、労働組合員の人数はおよそ何人か。最も近いものを以下の選択肢の中から１つ選びなさい。",
        "question3": "",
        "answer": "2",
        "score": 1,
        "choice1": "1,020千人",
        "choice2": "1,080千人",
        "choice3": "1,820千人",
        "choice4": "1,880千人",
        "choice5": "2,020千人",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 4,
        "shikenId": 1,
        "parentNo": 4,
        "no": 1,
        "answerType": 1,
        "question1": "■表を見て次の問いに答えなさい。<br> <br> 【牛乳乳製品生産量の対前年同月増加率（2021年度）】<br> <img src='/img/s3.png' class='c-img'/><br> 出典：政府統計の総合窓口(e-Stat)（https://www.e-stat.go.jp/）掲載資料を加工して作成",
        "question2": "次のうち、表から明らかに正しいと判断できるものを、以下の選択肢の中から１つ選びなさい。",
        "question3": "",
        "answer": "5",
        "score": 1,
        "choice1": "2021年10月のクリームの生産量を100とすると、2021年11月のそれは110と表すことができる。",
        "choice2": "2022年３月において、前年同月と比べた減少量が最も大きいのはれん乳類である。",
        "choice3": "脱脂粉乳の生産量は、2021年10月から2022年３月にかけて一貫して増加し続けている。",
        "choice4": "2021年９月のれん乳類の生産量は、2021年８月のそれの２倍を超えている。",
        "choice5": "2021年９月の全粉乳の生産量は、2020年９月のそれの２倍を超えている。",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 5,
        "shikenId": 1,
        "parentNo": 5,
        "no": 1,
        "answerType": 1,
        "question1": "■表を見て次の問いに答えなさい。<br> <br> 【車種別のガソリン使用量調査】<br> <img src='/img/s4.png' class='c-img' style='width:500px'/><br> 出典：政府統計の総合窓口(e-Stat)（https://www.e-stat.go.jp/）掲載資料を加工して作成",
        "question2": "走行距離当たりのガソリン消費量が最も多い車種はどれか。以下の選択肢の中から１つ選びなさい。",
        "question3": "",
        "answer": "3",
        "score": 1,
        "choice1": "営業用・貨物の普通・小型・特種車",
        "choice2": "営業用・旅客のバス・乗用車",
        "choice3": "自家用・旅客のバス・特種車",
        "choice4": "自家用・旅客のハイブリッド",
        "choice5": "自家用・旅客の普通車",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 6,
        "shikenId": 1,
        "parentNo": 6,
        "no": 1,
        "answerType": 1,
        "question1": "■グラフを見て次の問いに答えなさい。<br> <br> 【設備等機械類の製造受注状況】<br> <img src='/img/s5.png' class='c-img'/><br> 出典：政府統計の総合窓口(e-Stat)（https://www.e-stat.go.jp/）掲載資料を加工して作成",
        "question2": "2023年度の航空機の製造受注金額は、2022年度と比較しておよそ何％増加したか。最も近いものを以下の選択肢の中から１つ選びなさい。",
        "question3": "",
        "answer": "3",
        "score": 1,
        "choice1": "78%",
        "choice2": "98%",
        "choice3": "118%",
        "choice4": "138%",
        "choice5": "158%",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 7,
        "shikenId": 1,
        "parentNo": 7,
        "no": 1,
        "answerType": 1,
        "question1": "■表を見て次の問いに答えなさい。<br> <br> 【特定目的（３分野）別社内使用研究費の状況】<br> <img src='/img/s6.png' class='c-img'/><br> 出典：政府統計の総合窓口(e-Stat)（https://www.e-stat.go.jp/）掲載資料を加工して作成",
        "question2": "量子技術分野における2022年の１組織当たりの社内使用研究費を1とすると、2023年のそれはおよそいくつで表されるか。最も近いものを以下の選択肢の中から１つ選びなさい。",
        "question3": "",
        "answer": "2",
        "score": 1,
        "choice1": "1.8",
        "choice2": "2.1",
        "choice3": "2.4",
        "choice4": "2.7",
        "choice5": "3.0",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 8,
        "shikenId": 1,
        "parentNo": 8,
        "no": 1,
        "answerType": 1,
        "question1": "■グラフを見て次の問いに答えなさい。<br> <br> 【産業別就業人口（2022年）】<br> <img src='/img/s7.png' class='c-img'/><br> 出典：政府統計の総合窓口(e-Stat)（https://www.e-stat.go.jp/）掲載資料を加工して作成",
        "question2": "製造業に就業している人口のうち男性の割合が68.8％であるとき、製造業に就業している人口のうち女性の人数はおよそ何人か。最も近いものを以下の選択肢の中から１つ選びなさい。",
        "question3": "",
        "answer": "3",
        "score": 1,
        "choice1": "3,022.7千人",
        "choice2": "3,124.4千人",
        "choice3": "3,268.8千人",
        "choice4": "3,296.4千人",
        "choice5": "3.344.1千人",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 9,
        "shikenId": 1,
        "parentNo": 9,
        "no": 1,
        "answerType": 1,
        "question1": "■表を見て次の問いに答えなさい。<br> <br> 【森林資源の面積（2022年３月31日現在）】<br> <img src='/img/s8.png' class='c-img'/><br> 出典：政府統計の総合窓口(e-Stat)（https://www.e-stat.go.jp/）掲載資料を加工して作成",
        "question2": "合計に対する国有林の割合が最も高いものはどれか。以下の選択肢の中から１つ選びなさい。",
        "question3": "",
        "answer": "5",
        "score": 1,
        "choice1": "人工林・育成複層林",
        "choice2": "天然林・育成複層林",
        "choice3": "天然林・天然成林",
        "choice4": "無立木地・伐採跡地",
        "choice5": "無立木地・未立木地",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 10,
        "shikenId": 1,
        "parentNo": 10,
        "no": 1,
        "answerType": 1,
        "question1": "■グラフを見て次の問いに答えなさい。<br> <br> 【ある事業部の売上高推移】<br> <img src='/img/s9.png' class='c-img'/>",
        "question2": "2019年度の国内売上を１とすると、2022年度の国内売上はおよそいくつで表されるか。最も近いものを以下の選択肢の中から１つ選びなさい。",
        "question3": "",
        "answer": "1",
        "score": 1,
        "choice1": "1.47",
        "choice2": "1.49",
        "choice3": "1.51",
        "choice4": "1.53",
        "choice5": "1.55",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 11,
        "shikenId": 1,
        "parentNo": 11,
        "no": 1,
        "answerType": 1,
        "question1": "■グラフを見て次の問いに答えなさい。<br> <br> 【工場立地件数の地域別構成比】<br> <img src='/img/s10.png' class='c-img'/><br> 出典：政府統計の総合窓口(e-Stat)（https://www.e-stat.go.jp/）掲載資料を加工して作成",
        "question2": "2018年に関東地方に立地していた工場の件数が321件であるとき、同年の全国の工場立地件数は何件か。最も近いものを以下の選択肢の中から１つ選びなさい。",
        "question3": "",
        "answer": "2",
        "score": 1,
        "choice1": "1,028件",
        "choice2": "1,142件",
        "choice3": "1,258件",
        "choice4": "1,324件",
        "choice5": "1,444件",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 12,
        "shikenId": 1,
        "parentNo": 12,
        "no": 1,
        "answerType": 1,
        "question1": "■表を見て次の問いに答えなさい。<br> <br> 【刑法犯の認知件数・検挙件数・検挙率等の推移】<br> <img src='/img/s11.png' class='c-img'/><br> 出典：政府統計の総合窓口(e-Stat)（https://www.e-stat.go.jp/）掲載資料を加工して作成",
        "question2": "2015年の検挙人員のうち少年の人数は、2012年のそれと比較しておよそ何％減少したか。最も近いものを以下の選択肢の中から１つ選びなさい。",
        "question3": "",
        "answer": "4",
        "score": 1,
        "choice1": "6.5％",
        "choice2": "18.2％",
        "choice3": "32.4％",
        "choice4": "40.4％",
        "choice5": "52.3％",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 13,
        "shikenId": 1,
        "parentNo": 13,
        "no": 1,
        "answerType": 1,
        "question1": "■グラフを見て次の問いに答えなさい。<br> <br> 【まぐろ類・かじき類の漁業産出額（2021年）】<br> <img src='/img/s12.png' class='c-img'/><br> 出典：政府統計の総合窓口(e-Stat)（https://www.e-stat.go.jp/）掲載資料を加工して作成",
        "question2": "まぐろ類のうち「めばち」の漁業産出額を100とすると、かじき類のうち「めかじき」の漁業産出額はおよそいくつで表されるか。最も近いものを以下の選択肢の中から１つ選びなさい。",
        "question3": "",
        "answer": "3",
        "score": 1,
        "choice1": "10.1",
        "choice2": "13.2",
        "choice3": "16.3",
        "choice4": "19.4",
        "choice5": "22.5",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 14,
        "shikenId": 1,
        "parentNo": 14,
        "no": 1,
        "answerType": 1,
        "question1": "■表を見て次の問いに答えなさい。<br> <br> 【種類別旅券発行数】<br> <img src='/img/s13.png' class='c-img'/><br> 出典：政府統計の総合窓口(e-Stat)（https://www.e-stat.go.jp/）掲載資料を加工して作成",
        "question2": "2016年から2017年にかけての旅券発行数の増加率が最も高かったのはどれか。以下の選択肢の中から１つ選びなさい。",
        "question3": "",
        "answer": "2",
        "score": 1,
        "choice1": "一般旅券・５年",
        "choice2": "一般旅券・10年",
        "choice3": "一般旅券・記載変更",
        "choice4": "公用旅券・外交",
        "choice5": "公用旅券・公用",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 15,
        "shikenId": 1,
        "parentNo": 15,
        "no": 1,
        "answerType": 1,
        "question1": "■グラフを見て次の問いに答えなさい。<br> <br> 【地域別・海外在留邦人数（2016年）】<br> <img src='/img/s14.png' class='c-img'/><br> 出典：政府統計の総合窓口(e-Stat)（https://www.e-stat.go.jp/）掲載資料を加工して作成",
        "question2": "次の記述のうち、グラフを正しく説明しているものはいくつあるか。以下の選択肢の中から１つ選びなさい。<br> ・永住者の数が最も多い地域は南米である。<br> ・西欧における長期滞在者の数は、大洋州におけるそれの３倍を上回る。<br> ・長期滞在者の数が最も多い地域はアジアである。<br> ・永住者の総数は50万人を上回る。",
        "question3": "",
        "answer": "2",
        "score": 1,
        "choice1": "０",
        "choice2": "１つ",
        "choice3": "２つ",
        "choice4": "３つ",
        "choice5": "４つ",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 16,
        "shikenId": 1,
        "parentNo": 16,
        "no": 1,
        "answerType": 1,
        "question1": "■表を見て次の問いに答えなさい。<br> <br> 【火災の発生状況】<br> <img src='/img/s15.png' class='c-img'/><br> 出典：政府統計の総合窓口(e-Stat)（https://www.e-stat.go.jp/）掲載資料を加工して作成",
        "question2": "2011年から2016年までの６年間の車両の出火件数の平均はおよそいくらか。最も近いものを選択肢の中から１つ選びなさい。",
        "question3": "",
        "answer": "4",
        "score": 1,
        "choice1": "4,285件",
        "choice2": "4,435件",
        "choice3": "4,465件",
        "choice4": "4,495件",
        "choice5": "4,565件",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 17,
        "shikenId": 1,
        "parentNo": 17,
        "no": 1,
        "answerType": 1,
        "question1": "■グラフを見て次の問いに答えなさい。<br> <br> 【研究主体・組織別研究関係従業者数】<br> <img src='/img/s16.png' class='c-img'/><br> 出典：政府統計の総合窓口(e-Stat)（https://www.e-stat.go.jp/）掲載資料を加工して作成",
        "question2": "2014年から2024年の間の従業者数の増加率が最も大きいのはどれか。以下の選択肢の中から１つ選びなさい。",
        "question3": "",
        "answer": "1",
        "score": 1,
        "choice1": "企業",
        "choice2": "非営利団体",
        "choice3": "公的機関",
        "choice4": "大学等",
        "choice5": "合計",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 18,
        "shikenId": 1,
        "parentNo": 18,
        "no": 1,
        "answerType": 1,
        "question1": "■グラフを見て次の問いに答えなさい。<br> <br> 【たばこ店の年間売上高規模別店舗数の構成比】<br> <img src='/img/s17.png' class='c-img'/><br> 出典：政府統計の総合窓口(e-Stat)（https://www.e-stat.go.jp/）掲載資料を加工して作成",
        "question2": "昭和60年４月１日許可のたばこ店のうち、年間売上高が1,001万円以上である店舗数をＸとおくと、昭和60年４月２日以降許可のたばこ店のそれはおよそどのように表されるか。最も近いものを以下の選択肢の中から１つ選びなさい。",
        "question3": "",
        "answer": "3",
        "score": 1,
        "choice1": "0.59Ｘ",
        "choice2": "3.4Ｘ",
        "choice3": "5.9Ｘ",
        "choice4": "6.8Ｘ",
        "choice5": "8.2Ｘ",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 19,
        "shikenId": 1,
        "parentNo": 19,
        "no": 1,
        "answerType": 1,
        "question1": "■表を見て次の問いに答えなさい。<br> <br> 【国有財産売払収入の推移】<br> <img src='/img/s18.png' class='c-img'/><br> 出典：政府統計の総合窓口(e-Stat)（https://www.e-stat.go.jp/）掲載資料を加工して作成",
        "question2": "2013年度～2022年度のうち、土地の売払収入が一般会計に占める割合が最も小さい年度はどれか。以下の選択肢の中から１つ選びなさい。",
        "question3": "",
        "answer": "4",
        "score": 1,
        "choice1": "2014年度",
        "choice2": "2015年度",
        "choice3": "2018年度",
        "choice4": "2019年度",
        "choice5": "2021年度",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 20,
        "shikenId": 1,
        "parentNo": 20,
        "no": 1,
        "answerType": 1,
        "question1": "■表を見て次の問いに答えなさい。<br> <br> 【全国の病床の状況（2021年）】<br> <img src='/img/s19.png' class='c-img'/><br> 出典：政府統計の総合窓口(e-Stat)（https://www.e-stat.go.jp/）掲載資料を加工して作成",
        "question2": "１日平均在院患者数を病床数で除したものを病床利用率とするとき、この病床利用率が療養病床と最も近いものはどれか。正しいものを以下の選択肢の中から１つ選びなさい。",
        "question3": "",
        "answer": "4",
        "score": 1,
        "choice1": "一般病床",
        "choice2": "結核病床",
        "choice3": "感染症病床",
        "choice4": "精神病床",
        "choice5": "合計",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 21,
        "shikenId": 1,
        "parentNo": 21,
        "no": 1,
        "answerType": 1,
        "question1": "■グラフを見て次の問いに答えなさい。<br> <br> 【水産加工業の事業所数と兼業事業所の割合（2022年度）】<br> <img src='/img/s20.png' class='c-img'/><br> 出典：政府統計の総合窓口(e-Stat)（https://www.e-stat.go.jp/）掲載資料を加工して作成",
        "question2": "兼業の水産練製品の事業所数を100とすると、兼業の塩蔵品の事業所数はおよそいくつで表されるか。最も近いものを選択肢の中から１つ選びなさい。",
        "question3": "",
        "answer": "3",
        "score": 1,
        "choice1": "32",
        "choice2": "43",
        "choice3": "54",
        "choice4": "65",
        "choice5": "76",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 22,
        "shikenId": 1,
        "parentNo": 22,
        "no": 1,
        "answerType": 1,
        "question1": "■表を見て次の問いに答えなさい。<br> <br> 【一般船舶に乗り組む船員の現金給与額等の動き】<br> <img src='/img/s21.png' class='c-img'/><br> 出典：政府統計の総合窓口(e-Stat)（https://www.e-stat.go.jp/）掲載資料を加工して作成",
        "question2": "1990年の「きまって支給する給与」は、1980年のそれと比較しておよそ何％増加したか。最も近いものを以下の選択肢の中から１つ選びなさい。",
        "question3": "",
        "answer": "3",
        "score": 1,
        "choice1": "27.7％",
        "choice2": "29.7％",
        "choice3": "31.7％",
        "choice4": "33.7％",
        "choice5": "35.7％",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 23,
        "shikenId": 1,
        "parentNo": 23,
        "no": 1,
        "answerType": 1,
        "question1": "■グラフを見て次の問いに答えなさい。<br> <br> 【建築物リフォーム・リニューアル受注高の対前年度増加率】<br> <img src='/img/s22.png' class='c-img'/><br> 出典：政府統計の総合窓口(e-Stat)（https://www.e-stat.go.jp/）掲載資料を加工して作成",
        "question2": "次のうち、グラフから明らかに正しいと判断できるものを、以下の選択肢の中から１つ選びなさい。",
        "question3": "",
        "answer": "1",
        "score": 1,
        "choice1": "2017年度における非住宅建築物のリフォーム・リニューアル受注高が86,578億円であれば、2019年度におけるそれは92,000億円を上回る。",
        "choice2": "2021年度における非住宅建築物のリフォーム・リニューアル受注高が79,501億円であれば、2022年度におけるそれは76,000億円を下回る。",
        "choice3": "2023年度における建築物リフォーム・リニューアル受注高は、非住宅建築物が住宅を上回っている。",
        "choice4": "住宅、非住宅建築物ともに、2022年度における建築物リフォーム・リニューアル受注高は2018年度のそれを上回っている。",
        "choice5": "2018年度～2023年度のうち、住宅のリフォーム・リニューアル受注高が最低なのは2019年度である。",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 24,
        "shikenId": 1,
        "parentNo": 24,
        "no": 1,
        "answerType": 1,
        "question1": "■グラフを見て次の問いに答えなさい。<br> <br> 【建設投資の投資額】<br> <img src='/img/s23.png' class='c-img'/><br> 出典：政府統計の総合窓口(e-Stat)（https://www.e-stat.go.jp/）掲載資料を加工して作成",
        "question2": "2015年度から2024年度にかけて、建築・住宅の投資額実質値はどのように変化したか。以下の選択肢の中から１つ選びなさい。",
        "question3": "",
        "answer": "5",
        "score": 1,
        "choice1": "17.4％増加",
        "choice2": "9.7％増加",
        "choice3": "ほぼ横ばい",
        "choice4": "9.7％減少",
        "choice5": "17.4％減少",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 25,
        "shikenId": 1,
        "parentNo": 25,
        "no": 1,
        "answerType": 1,
        "question1": "■グラフを見て次の問いに答えなさい。<br> <br> 【火力発電の燃料別発受電量の構成比】<br> <img src='/img/s24.png' class='c-img'/><br> 出典：政府統計の総合窓口(e-Stat)（https://www.e-stat.go.jp/）掲載資料を加工して作成",
        "question2": "2013年度～2015年度の３年度間におけるLNGを燃料とした発受電量の平均はおよそいくらか。最も近いものを以下の選択肢の中から１つ選びなさい。",
        "question3": "",
        "answer": "3",
        "score": 1,
        "choice1": "398,900,000千kWh",
        "choice2": "412,400,000千kWh",
        "choice3": "424,200,000千kWh",
        "choice4": "437,100,000千kWh",
        "choice5": "449,400,000千kWh",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 26,
        "shikenId": 1,
        "parentNo": 26,
        "no": 1,
        "answerType": 1,
        "question1": "■グラフを見て次の問いに答えなさい。<br> <br> 【通信・放送事業者売上高の対前年同月増加率】<br> <img src='/img/s25.png' class='c-img'/><br> 出典：政府統計の総合窓口(e-Stat)（https://www.e-stat.go.jp/）掲載資料を加工して作成",
        "question2": "民間放送事業の2015年８月の売上高が138,572百万円であるとき、2016年8月のそれはおよそいくらか。最も近いものを以下の選択肢の中から１つ選びなさい。",
        "question3": "",
        "answer": "5",
        "score": 1,
        "choice1": "91,500百万円",
        "choice2": "101,500百万円",
        "choice3": "111,500百万円",
        "choice4": "121,500百万円",
        "choice5": "131,500百万円",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 27,
        "shikenId": 1,
        "parentNo": 27,
        "no": 1,
        "answerType": 1,
        "question1": "■表を見て次の問いに答えなさい。<br> <br> 【医療機器の販売先別売上高】<br> <img src='/img/s26.png' class='c-img'/><br> 出典：政府統計の総合窓口(e-Stat)（https://www.e-stat.go.jp/）掲載資料を加工して作成",
        "question2": "病院向けの売上高が合計に占める割合が最も大きい年度はどれか。以下の選択肢の中から１つ選びなさい。",
        "question3": "",
        "answer": "2",
        "score": 1,
        "choice1": "2010年度",
        "choice2": "2011年度",
        "choice3": "2012年度",
        "choice4": "2014年度",
        "choice5": "2015年度",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 28,
        "shikenId": 1,
        "parentNo": 28,
        "no": 1,
        "answerType": 1,
        "question1": "■表を見て次の問いに答えなさい。<br> <br> 【農道の管理主体別延長距離（2022年８月１日現在）】<br> <img src='/img/s27.png' class='c-img'/><br> 出典：政府統計の総合窓口(e-Stat)（https://www.e-stat.go.jp/）掲載資料を加工して作成",
        "question2": "管理主体が市町村である農道の延長距離が合計に占める割合が、近畿と最も近い農業地域はどれか。正しいものを以下の選択肢の中から１つ選びなさい。",
        "question3": "",
        "answer": "4",
        "score": 1,
        "choice1": "東北",
        "choice2": "関東・東山",
        "choice3": "中国",
        "choice4": "四国",
        "choice5": "上のいずれでもない",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 29,
        "shikenId": 1,
        "parentNo": 29,
        "no": 1,
        "answerType": 1,
        "question1": "■グラフを見て次の問いに答えなさい。<br> <br> 【貯油タンク・液化石油ガス以外の石油設備容量（2016年）】<br> <img src='/img/s28.png' class='c-img'/><br> 出典：政府統計の総合窓口(e-Stat)（https://www.e-stat.go.jp/）掲載資料を加工して作成",
        "question2": "半製品における「その他」の石油設備容量を100とすると、燃料油のそれはおよそいくつで表されるか。最も近いものを以下の選択肢の中から１つ選びなさい。",
        "question3": "",
        "answer": "3",
        "score": 1,
        "choice1": "389",
        "choice2": "399",
        "choice3": "409",
        "choice4": "419",
        "choice5": "429",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 30,
        "shikenId": 1,
        "parentNo": 30,
        "no": 1,
        "answerType": 1,
        "question1": "■表を見て次の問いに答えなさい。<br> <br> 【輸出先／輸入先別・金統計品の貿易規模（2018年）】<br> <img src='/img/s29.png' class='c-img'/><br> 出典：政府統計の総合窓口(e-Stat)（https://www.e-stat.go.jp/）掲載資料を加工して作成",
        "question2": "次のうち、日本が金統計品を輸入した金額に対する金統計品を輸出した金額の割合が最も小さい国はどこか。以下の選択肢の中から１つ選びなさい。",
        "question3": "",
        "answer": "5",
        "score": 1,
        "choice1": "オーストリア",
        "choice2": "スイス",
        "choice3": "カナダ",
        "choice4": "香港",
        "choice5": "オーストラリア",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 31,
        "shikenId": 1,
        "parentNo": 31,
        "no": 1,
        "answerType": 1,
        "question1": "■表を見て次の問いに答えなさい。<br> <br> 【原因食品ごとの食中毒発生状況】<br> <img src='/img/s30.png' class='c-img'/><br> 出典：政府統計の総合窓口(e-Stat)（https://www.e-stat.go.jp/）掲載資料を加工して作成",
        "question2": "魚介類を原因食品とする食中毒について2015年と2017年を比べると、事件１件当たりの患者数はどのように変化したか。以下の選択肢の中から１つ選びなさい。",
        "question3": "",
        "answer": "1",
        "score": 1,
        "choice1": "３倍以上に増加した",
        "choice2": "約２倍に増加した",
        "choice3": "ほぼ横ばい",
        "choice4": "約２分の１に減少した",
        "choice5": "約３分の１に減少した",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 32,
        "shikenId": 1,
        "parentNo": 32,
        "no": 1,
        "answerType": 1,
        "question1": "■グラフを見て次の問いに答えなさい。<br> <br> 【正社員の残業時間についての企業の回答分布】<br> <img src='/img/s31.png' class='c-img' style='width:500px'/><br> 出典：政府統計の総合窓口(e-Stat)（https://www.e-stat.go.jp/）掲載資料を加工して作成",
        "question2": "正社員の残業時間が80時間以上であると回答した企業数をＸとおくと、10～19時間であると回答した企業数はどのように表されるか。以下の選択肢の中から１つ選びなさい。",
        "question3": "",
        "answer": "3",
        "score": 1,
        "choice1": "1.3X/24.2",
        "choice2": "1.3/24.2X",
        "choice3": "24.2X/1.3",
        "choice4": "24.2/1.3X",
        "choice5": "24.2X＋1.3",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 33,
        "shikenId": 1,
        "parentNo": 33,
        "no": 1,
        "answerType": 1,
        "question1": "■表を見て次の問いに答えなさい。<br> <br> 【戸籍の処理事件数】<br> <img src='/img/s32.png' class='c-img'/><br> 出典：政府統計の総合窓口(e-Stat)（https://www.e-stat.go.jp/）掲載資料を加工して作成",
        "question2": "次のうち、表から明らかに正しいと判断できるものを、以下の選択肢の中から１つ選びなさい。",
        "question3": "",
        "answer": "2",
        "score": 1,
        "choice1": "表中のすべての年度において、新戸籍編製の処理事件数は戸籍全部消除の処理事件数を下回る。",
        "choice2": "2020年度から2021年度にかけての処理事件数の増加率を比べると、戸籍の再製・補完は違反通知を10ポイント以上上回る。",
        "choice3": "戸籍全部消除の処理事件数が総数に占める割合は、表中の５年度間において増加し続けている。",
        "choice4": "表中の５年度間における違反通知の処理事件数の平均は6,200件を上回る。",
        "choice5": "その他の処理事件数は2020年度以降毎年度減少しており、前の年度に比べた減少率が最も大きいのは2023年度である。",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 34,
        "shikenId": 1,
        "parentNo": 34,
        "no": 1,
        "answerType": 1,
        "question1": "■グラフを見て次の問いに答えなさい。<br> <br> 【産業廃棄物の地域別排出量】<br> <img src='/img/s33.png' class='c-img'/><br> 出典：政府統計の総合窓口(e-Stat)（https://www.e-stat.go.jp/）掲載資料を加工して作成",
        "question2": "2010年度の排出総量が385,988千t、2014年度の排出総量が392,840千tであるとき、この2年度間を比べると九州地域の排出量はどのように変化したか。以下の選択肢の中から１つ選びなさい。",
        "question3": "",
        "answer": "4",
        "score": 1,
        "choice1": "1,000千t超増加した",
        "choice2": "500～1,000千t増加した",
        "choice3": "ほぼ横ばい",
        "choice4": "500～1,000千t減少した",
        "choice5": "1,000千t超減少した",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 35,
        "shikenId": 1,
        "parentNo": 35,
        "no": 1,
        "answerType": 1,
        "question1": "■表を見て次の問いに答えなさい。<br> <br> 【発注者別・月別工事費】<br> <img src='/img/s34.png' class='c-img'/><br> 出典：政府統計の総合窓口(e-Stat)（https://www.e-stat.go.jp/）掲載資料を加工して作成",
        "question2": "表中の６月間における市区町村を発注者とする工事費の平均値が676,090百万円であるとき、Ｘに入る数値はおよそいくつか。最も近いものを以下の選択肢の中から１つ選びなさい。",
        "question3": "",
        "answer": "2",
        "score": 1,
        "choice1": "572,600",
        "choice2": "596,300",
        "choice3": "603,700",
        "choice4": "614,200",
        "choice5": "635,800",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 36,
        "shikenId": 1,
        "parentNo": 36,
        "no": 1,
        "answerType": 1,
        "question1": "■表を見て次の問いに答えなさい。<br> <br> 【地域別・進出日系企業数】<br> <img src='/img/s35.png' class='c-img'/><br> 出典：政府統計の総合窓口(e-Stat)（https://www.e-stat.go.jp/）掲載資料を加工して作成",
        "question2": "2014年と2016年の進出日系企業数を比べたとき、増加率が総数と最も近い地域はどれか。以下の選択肢の中から１つ選びなさい。",
        "question3": "",
        "answer": "3",
        "score": 1,
        "choice1": "アジア・大洋州",
        "choice2": "北米・中米・南米",
        "choice3": "西欧・東欧・旧ソ連",
        "choice4": "中東",
        "choice5": "アフリカ",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 37,
        "shikenId": 1,
        "parentNo": 37,
        "no": 1,
        "answerType": 1,
        "question1": "■表を見て次の問いに答えなさい。<br> <br> 【建設業における工事受注状況】<br> <img src='/img/s36.png' class='c-img'/><br> 出典：政府統計の総合窓口(e-Stat)（https://www.e-stat.go.jp/）掲載資料を加工して作成",
        "question2": "2014年度における元請工事の発注会社１社当たりの受注金額を１とすると、2016年度におけるそれはおよそいくつで表されるか。最も近いものを以下の選択肢の中から１つ選びなさい。",
        "question3": "",
        "answer": "2",
        "score": 1,
        "choice1": "0.62",
        "choice2": "1.55",
        "choice3": "2.34",
        "choice4": "3.47",
        "choice5": "4.96",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 38,
        "shikenId": 1,
        "parentNo": 38,
        "no": 1,
        "answerType": 1,
        "question1": "■グラフを見て次の問いに答えなさい。<br> <br> 【ばい煙の排出量】<br> <img src='/img/s37.png' class='c-img'/><br> 出典：政府統計の総合窓口(e-Stat)（https://www.e-stat.go.jp/）掲載資料を加工して作成",
        "question2": "2014年度における１施設当たりのばい煙の排出量が6.85トン/年であるとき、Ｘの値はおよそいくつか。最も近いものを以下の選択肢の中から１つ選びなさい。",
        "question3": "",
        "answer": "3",
        "score": 1,
        "choice1": "592,000",
        "choice2": "612,000",
        "choice3": "631,000",
        "choice4": "649,000",
        "choice5": "671,000",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 39,
        "shikenId": 1,
        "parentNo": 39,
        "no": 1,
        "answerType": 1,
        "question1": "■表を見て次の問いに答えなさい。<br> <br> 【少年鑑別所への新収容者の年齢分布】<br> <img src='/img/s38.png' class='c-img'/><br> 出典：政府統計の総合窓口(e-Stat)（https://www.e-stat.go.jp/）掲載資料を加工して作成",
        "question2": "次の記述のうち、表を正しく説明しているものはいくつあるか。以下の選択肢の中から１つ選びなさい。<br> ・2019年について見ると、高い年齢区分であるほど新収容者に占める女性の割合が低い。<br> ・表中の４つの年齢区分のすべてにおいて、年を追うごとに新収容者数は減少している。<br> ・2019年における16～18歳の新収容者数を100とすると、2023年のそれは90を上回る。<br> ・表中の５年間のすべてにおいて、新収容者の総数に占める女性の割合は１割を下回る。",
        "question3": "",
        "answer": "3",
        "score": 1,
        "choice1": "０",
        "choice2": "１つ",
        "choice3": "２つ",
        "choice4": "３つ",
        "choice5": "４つ",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 40,
        "shikenId": 1,
        "parentNo": 40,
        "no": 1,
        "answerType": 1,
        "question1": "■グラフを見て次の問いに答えなさい。<br> <br> 【甲乙丙町間のバスの運行】<br> <img src='/img/s39.png' class='c-img'/>",
        "question2": "甲町を８時25分に出発したバスが、丙町から甲町に向かうバスと、乙町と丙町の間で出会う。その出会う時刻を以下の選択肢の中から１つ選びなさい。",
        "question3": "",
        "answer": "4",
        "score": 1,
        "choice1": "８時32分30秒",
        "choice2": "８時40分",
        "choice3": "８時50分",
        "choice4": "８時57分30秒",
        "choice5": "上記のいずれでもない",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 41,
        "shikenId": 2,
        "parentNo": 1,
        "no": 1,
        "answerType": 1,
        "question1": "■あるメーカーで、工場ごとの製品製造数を集計しています。<br> <br> 【工場ごとの製品製造数】<br> <img src='/img/ss1.png' class='c-img'/>",
        "question2": "工場Ｅの午後勤務スタッフ数は何人と推測できるか。",
        "question3": "",
        "answer": "3",
        "score": 1,
        "choice1": "69人",
        "choice2": "73人",
        "choice3": "77人",
        "choice4": "81人",
        "choice5": "85人",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 42,
        "shikenId": 2,
        "parentNo": 2,
        "no": 1,
        "answerType": 1,
        "question1": "■ある会社で、新規事業の予想利益を試算しています。<br> <br> 【新規事業の利益試算】<br> <img src='/img/ss2.png' class='c-img'/>",
        "question2": "６事業年度目の予想利益はいくらと推測できるか。",
        "question3": "",
        "answer": "4",
        "score": 1,
        "choice1": "810千円",
        "choice2": "824千円",
        "choice3": "842千円",
        "choice4": "850千円",
        "choice5": "884千円",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 43,
        "shikenId": 2,
        "parentNo": 3,
        "no": 1,
        "answerType": 1,
        "question1": "■ある飲食店で、ランチメニューの注文数を月ごとに集計しています。<br> <br> 【ランチメニューの注文数】<br> <img src='/img/ss3.png' class='c-img'/>",
        "question2": "８月のＢランチの注文数は何件と推測できるか。",
        "question3": "",
        "answer": "1",
        "score": 1,
        "choice1": "42件",
        "choice2": "45件",
        "choice3": "48件",
        "choice4": "51件",
        "choice5": "54件",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 44,
        "shikenId": 2,
        "parentNo": 4,
        "no": 1,
        "answerType": 1,
        "question1": "■ある県で、小学生のインフルエンザ罹患者数を集計しています。<br> <br> 【県内の小学校におけるインフルエンザ罹患者数】<br> <img src='/img/ss4.png' class='c-img'/>",
        "question2": "Ｅ市における罹患者数は何人と推測できるか。",
        "question3": "",
        "answer": "4",
        "score": 1,
        "choice1": "114人",
        "choice2": "133人",
        "choice3": "164人",
        "choice4": "229人",
        "choice5": "286人",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 45,
        "shikenId": 2,
        "parentNo": 5,
        "no": 1,
        "answerType": 1,
        "question1": "■ある航空会社が、飛行機の運賃をまとめています。<br> <br> 【航空運賃表】<br> <img src='/img/ss5.png' class='c-img'/>",
        "question2": "Ｅ便の運賃はいくらと推測できるか。",
        "question3": "",
        "answer": "3",
        "score": 1,
        "choice1": "29,700円",
        "choice2": "32,200円",
        "choice3": "35,700円",
        "choice4": "38,200円",
        "choice5": "40,700円",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 46,
        "shikenId": 2,
        "parentNo": 6,
        "no": 1,
        "answerType": 1,
        "question1": "■ある企業で、採用人員の推移についてまとめています。<br> <br> 【採用人員の推移】<br> <img src='/img/ss6.png' class='c-img'/>",
        "question2": "2022年の中途採用者数は何人と推測できるか。",
        "question3": "",
        "answer": "5",
        "score": 1,
        "choice1": "35人",
        "choice2": "39人",
        "choice3": "43人",
        "choice4": "47人",
        "choice5": "51人",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 47,
        "shikenId": 2,
        "parentNo": 7,
        "no": 1,
        "answerType": 1,
        "question1": "■ある携帯電話会社が、学生割引料金を検討しています。<br> <br> 【携帯電話料金表】<br> <img src='/img/ss7.png' class='c-img'/>",
        "question2": "プランＤの学生割引料金は月額いくらと推測できるか。",
        "question3": "",
        "answer": "5",
        "score": 1,
        "choice1": "6,500円",
        "choice2": "6,600円",
        "choice3": "6,700円",
        "choice4": "6,800円",
        "choice5": "6,900円",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 48,
        "shikenId": 2,
        "parentNo": 8,
        "no": 1,
        "answerType": 1,
        "question1": "■ある企業で、通信教育講座の受講料を検討しています。<br> <br> 【通信教育講座の受講料】<br> <img src='/img/ss8.png' class='c-img'/>",
        "question2": "講座Ｅの受講料はいくらと推測できるか。",
        "question3": "",
        "answer": "4",
        "score": 1,
        "choice1": "40,000円",
        "choice2": "45,000円",
        "choice3": "50,000円",
        "choice4": "55,000円",
        "choice5": "60,000円",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 49,
        "shikenId": 2,
        "parentNo": 9,
        "no": 1,
        "answerType": 1,
        "question1": "■あるサークルで、合宿の参加人数と費用の計算をしています。<br> <br> 【合宿参加人数と総費用】<br> <img src='/img/ss9.png' class='c-img'/>",
        "question2": "出席人数が100人のときの総費用はいくらと推測できるか。",
        "question3": "",
        "answer": "3",
        "score": 1,
        "choice1": "1,380千円",
        "choice2": "1,410千円",
        "choice3": "1,440千円",
        "choice4": "1,470千円",
        "choice5": "1,500千円",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 50,
        "shikenId": 2,
        "parentNo": 10,
        "no": 1,
        "answerType": 1,
        "question1": "■ある会員向けサービスで、新規入会者と退会者についてまとめています。<br> <br> 【会員向けサービスの新規入会者・退会者】<br> <img src='/img/ss10.png' class='c-img'/>",
        "question2": "７月の退会者は何人と推測できるか。",
        "question3": "",
        "answer": "3",
        "score": 1,
        "choice1": "69人",
        "choice2": "70人",
        "choice3": "71人",
        "choice4": "72人",
        "choice5": "73人",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 51,
        "shikenId": 2,
        "parentNo": 11,
        "no": 1,
        "answerType": 1,
        "question1": "■ある飲食チェーン店で、年度ごとの出店数をまとめています。<br> <br> 【新規出店数】<br> <img src='/img/ss11.png' class='c-img'/>",
        "question2": "2022年度の新規出店数は何店と推測できるか。",
        "question3": "",
        "answer": "3",
        "score": 1,
        "choice1": "22店",
        "choice2": "23店",
        "choice3": "24店",
        "choice4": "25店",
        "choice5": "26店",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 52,
        "shikenId": 2,
        "parentNo": 12,
        "no": 1,
        "answerType": 1,
        "question1": "■ある企業で、新規プロジェクトの人員表を作成しています。<br> <br> 【新規プロジェクトの人員表】<br> <img src='/img/ss12.png' class='c-img'/>",
        "question2": "プロジェクトＥに参加している広報部門の人員は何人と推測できるか。",
        "question3": "",
        "answer": "5",
        "score": 1,
        "choice1": "38人",
        "choice2": "39人",
        "choice3": "40人",
        "choice4": "41人",
        "choice5": "42人",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 53,
        "shikenId": 2,
        "parentNo": 13,
        "no": 1,
        "answerType": 1,
        "question1": "■あるメーカーで、新商品の開発費用を試算しています。<br> <br> 【新商品の開発費用試算】<br> <img src='/img/ss13.png' class='c-img'/>",
        "question2": "商品Ｅの宣伝費はいくらと推測できるか。",
        "question3": "",
        "answer": "3",
        "score": 1,
        "choice1": "55百万円",
        "choice2": "105百万円",
        "choice3": "155百万円",
        "choice4": "205百万円",
        "choice5": "255百万円",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 54,
        "shikenId": 2,
        "parentNo": 14,
        "no": 1,
        "answerType": 1,
        "question1": "■ある企業で、営業所の新規立ち上げに伴い、PCの設置数を検討しています。<br> <br> 【業務用PCの設置数】<br> <img src='/img/ss14.png' class='c-img'/>",
        "question2": "部署Ｅに設置すべきPCは何台と推測できるか。",
        "question3": "",
        "answer": "5",
        "score": 1,
        "choice1": "50台",
        "choice2": "54台",
        "choice3": "58台",
        "choice4": "62台",
        "choice5": "66台",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 55,
        "shikenId": 2,
        "parentNo": 15,
        "no": 1,
        "answerType": 1,
        "question1": "■ある団体が、採用試験の実施状況についてまとめています。<br> <br> 【採用試験の実施状況】<br> <img src='/img/ss15.png' class='c-img'/>",
        "question2": "2022年度の内定者数は何人と推測できるか。",
        "question3": "",
        "answer": "4",
        "score": 1,
        "choice1": "75人",
        "choice2": "80人",
        "choice3": "85人",
        "choice4": "90人",
        "choice5": "95人",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 56,
        "shikenId": 2,
        "parentNo": 16,
        "no": 1,
        "answerType": 1,
        "question1": "■あるテーマパークで、入場料の団体割引を検討しています。<br> <br> 【入場料の団体割引】<br> <img src='/img/ss16.png' class='c-img'/>",
        "question2": "50人の団体割引における入場料総額はいくらと推測できるか。",
        "question3": "",
        "answer": "5",
        "score": 1,
        "choice1": "18,100円",
        "choice2": "18,200円",
        "choice3": "18,300円",
        "choice4": "18,400円",
        "choice5": "18,500円",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 57,
        "shikenId": 2,
        "parentNo": 17,
        "no": 1,
        "answerType": 1,
        "question1": "■ある企業で、新規事業の売上見込について検討しています。<br> <br> 【新規事業売上見込】<br> <img src='/img/ss17.png' class='c-img'/>",
        "question2": "５年目の売上高はいくらと推測できるか。",
        "question3": "",
        "answer": "4",
        "score": 1,
        "choice1": "25,000千円",
        "choice2": "27,000千円",
        "choice3": "29,000千円",
        "choice4": "31,000千円",
        "choice5": "33,000千円",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 58,
        "shikenId": 2,
        "parentNo": 18,
        "no": 1,
        "answerType": 1,
        "question1": "■ある演劇公演で、前売り券と当日券の販売数をまとめています。<br> <br> 【前売り券と当日券の販売数】<br> <img src='/img/ss18.png' class='c-img'/>",
        "question2": "Ｅ会場の当日券販売数は何枚と推測できるか。",
        "question3": "",
        "answer": "4",
        "score": 1,
        "choice1": "290枚",
        "choice2": "330枚",
        "choice3": "370枚",
        "choice4": "410枚",
        "choice5": "450枚",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 59,
        "shikenId": 2,
        "parentNo": 19,
        "no": 1,
        "answerType": 1,
        "question1": "■あるダイニングで、ランチ営業の売上を集計しています。<br> <br> 【ランチ営業の売上集計】<br> <img src='/img/ss19.png' class='c-img'/>",
        "question2": "９月のランチＣの売上はいくらと推測できるか。",
        "question3": "",
        "answer": "1",
        "score": 1,
        "choice1": "506,000円",
        "choice2": "516,000円",
        "choice3": "526,000円",
        "choice4": "536,000円",
        "choice5": "546,000円",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 60,
        "shikenId": 2,
        "parentNo": 20,
        "no": 1,
        "answerType": 1,
        "question1": "■あるメーカーで、新商品の売上高を集計しています。<br> <br> 【新商品の売上高】<br> <img src='/img/ss20.png' class='c-img'/>",
        "question2": "2022年の売上高はいくらと推測できるか。",
        "question3": "",
        "answer": "1",
        "score": 1,
        "choice1": "14,515千円",
        "choice2": "14,625千円",
        "choice3": "14,735千円",
        "choice4": "14,845千円",
        "choice5": "14,955千円",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 61,
        "shikenId": 2,
        "parentNo": 21,
        "no": 1,
        "answerType": 1,
        "question1": "■ある不動産会社が、不動産物件価格の再評価を行っています。<br> <br> 【不動産物件価格の再評価資料】<br> <img src='/img/ss21.png' class='c-img'/>",
        "question2": "Ｅ-501号室の再評価価格はいくらと推測できるか。",
        "question3": "",
        "answer": "2",
        "score": 1,
        "choice1": "3,570万円",
        "choice2": "3,690万円",
        "choice3": "3,810万円",
        "choice4": "3,930万円",
        "choice5": "4,050万円",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 62,
        "shikenId": 2,
        "parentNo": 22,
        "no": 1,
        "answerType": 1,
        "question1": "■ある会社が、イベントごとの売上データをまとめています。<br> <br> 【イベント売上データ】<br> <img src='/img/ss22.png' class='c-img'/>",
        "question2": "イベントＥの売上高はいくらと推測できるか。",
        "question3": "",
        "answer": "2",
        "score": 1,
        "choice1": "169百万円",
        "choice2": "189百万円",
        "choice3": "209百万円",
        "choice4": "229百万円",
        "choice5": "249百万円",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 63,
        "shikenId": 2,
        "parentNo": 23,
        "no": 1,
        "answerType": 1,
        "question1": "■ある医療機器メーカーが、コンピュータのリース料を検討しています。<br> <br> 【コンピュータリース料】<br> <img src='/img/ss23.png' class='c-img'/>",
        "question2": "病院Ｅのリース料合計はいくらと推測できるか。",
        "question3": "",
        "answer": "3",
        "score": 1,
        "choice1": "291,000円／月",
        "choice2": "292,000円／月",
        "choice3": "293,000円／月",
        "choice4": "294,000円／月",
        "choice5": "295,000円／月",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 64,
        "shikenId": 2,
        "parentNo": 24,
        "no": 1,
        "answerType": 1,
        "question1": "■あるコンビニエンスストアチェーンで、店舗ごとの売上実績をまとめています。<br> <br> 【店舗別売上高】<br> <img src='/img/ss24.png' class='c-img'/>",
        "question2": "店舗Ｆの当月売上実績はいくらと推測できるか。",
        "question3": "",
        "answer": "4",
        "score": 1,
        "choice1": "16,800千円",
        "choice2": "17,800千円",
        "choice3": "18,800千円",
        "choice4": "19,800千円",
        "choice5": "20,800千円",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 65,
        "shikenId": 2,
        "parentNo": 25,
        "no": 1,
        "answerType": 1,
        "question1": "■ある公共施設で、団体ごとの利用料金の見積もりを作成しています。<br> <br> 【団体利用料金見積もり】<br> <img src='/img/ss25.png' class='c-img'/>",
        "question2": "団体Ｆの利用料金はいくらと推測できるか。",
        "question3": "",
        "answer": "2",
        "score": 1,
        "choice1": "32,000円",
        "choice2": "36,000円",
        "choice3": "40,000円",
        "choice4": "44,000円",
        "choice5": "48,000円",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 66,
        "shikenId": 2,
        "parentNo": 26,
        "no": 1,
        "answerType": 1,
        "question1": "■あるカフェチェーンが、店舗別の１日の売上高を集計しています。<br> <br> 【店舗別売上高】<br> <img src='/img/ss26.png' class='c-img'/>",
        "question2": "店舗Fのアルコールの売上高はいくらと推測できるか。",
        "question3": "",
        "answer": "4",
        "score": 1,
        "choice1": "25,400円",
        "choice2": "29,400円",
        "choice3": "33,400円",
        "choice4": "37,400円",
        "choice5": "41,400円",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 67,
        "shikenId": 2,
        "parentNo": 27,
        "no": 1,
        "answerType": 1,
        "question1": "■あるレストランで、コースごとの料金を設定しています。<br> <br> 【コース別料金】<br> <img src='/img/ss27.png' class='c-img'/>",
        "question2": "Ｆコースのデザート付き料金はいくらと推測できるか。",
        "question3": "",
        "answer": "3",
        "score": 1,
        "choice1": "12,000円",
        "choice2": "12,500円",
        "choice3": "13,500円",
        "choice4": "14,000円",
        "choice5": "14,500円",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 68,
        "shikenId": 2,
        "parentNo": 28,
        "no": 1,
        "answerType": 1,
        "question1": "■ある動物園で、来園者の状況を月別にまとめています。<br> <br> 【月別来園者数】<br> <img src='/img/ss28.png' class='c-img'/>",
        "question2": "９月の平均来場者数は何人と推測できるか。",
        "question3": "",
        "answer": "2",
        "score": 1,
        "choice1": "396人／日",
        "choice2": "552人／日",
        "choice3": "796人／日",
        "choice4": "952人／日",
        "choice5": "1,196人／日",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 69,
        "shikenId": 2,
        "parentNo": 29,
        "no": 1,
        "answerType": 1,
        "question1": "■ある工場で、生産ラインごとの費用について集計しています。<br> <br> 【生産ラインごとの費用】<br> <img src='/img/ss29.png' class='c-img'/>",
        "question2": "ラインＥの費用総額はいくらと推測できるか。",
        "question3": "",
        "answer": "5",
        "score": 1,
        "choice1": "480,000円／日",
        "choice2": "520,000円／日",
        "choice3": "560,000円／日",
        "choice4": "600,000円／日",
        "choice5": "640,000円／日",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 70,
        "shikenId": 2,
        "parentNo": 30,
        "no": 1,
        "answerType": 1,
        "question1": "■ある工場で、製品の製造工程ごとに従業員１人当たりの処理件数をまとめています。<br> <br> 【製造工程ごとの従業員１人当たり処理件数】<br> <img src='/img/ss30.png' class='c-img'/>",
        "question2": "製品Ｅの工程2の配置人員は何人と推測できるか。",
        "question3": "",
        "answer": "1",
        "score": 1,
        "choice1": "20人",
        "choice2": "24人",
        "choice3": "28人",
        "choice4": "32人",
        "choice5": "36人",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 71,
        "shikenId": 2,
        "parentNo": 31,
        "no": 1,
        "answerType": 1,
        "question1": "■ある旅行会社で、ツアー商品の料金プランを検討しています。<br> <br> 【ツアー料金プラン表】<br> <img src='/img/ss31.png' class='c-img'/>",
        "question2": "プランＦの料金はいくらと推測できるか。",
        "question3": "",
        "answer": "3",
        "score": 1,
        "choice1": "21,000円",
        "choice2": "24,000円",
        "choice3": "27,000円",
        "choice4": "33,000円",
        "choice5": "36,000円",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 72,
        "shikenId": 2,
        "parentNo": 32,
        "no": 1,
        "answerType": 1,
        "question1": "■あるイベント会社が、イベントで配付したアンケートの回収状況をまとめています。<br> <br> 【アンケートの回収状況】<br> <img src='/img/ss32.png' class='c-img'/>",
        "question2": "会場Ｃにおける回収枚数は何枚と推測できるか。",
        "question3": "",
        "answer": "4",
        "score": 1,
        "choice1": "294枚",
        "choice2": "306枚",
        "choice3": "318枚",
        "choice4": "330枚",
        "choice5": "342枚",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 73,
        "shikenId": 2,
        "parentNo": 33,
        "no": 1,
        "answerType": 1,
        "question1": "■ある市で、転入者・転出者の状況をまとめています。<br> <br> 【転入・転出の状況】<br> <img src='/img/ss33.png' class='c-img'/>",
        "question2": "2022年の転出者は何人と推測できるか。",
        "question3": "",
        "answer": "4",
        "score": 1,
        "choice1": "7,998人",
        "choice2": "8,099人",
        "choice3": "8,200人",
        "choice4": "8,301人",
        "choice5": "8,402人",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 74,
        "shikenId": 2,
        "parentNo": 34,
        "no": 1,
        "answerType": 1,
        "question1": "■あるメーカーで、製品の価格の推移についてまとめています。<br> <br> 【製品価格の推移】<br> <img src='/img/ss34.png' class='c-img'/>",
        "question2": "2022年の製品価格はいくらと推測できるか。",
        "question3": "",
        "answer": "4",
        "score": 1,
        "choice1": "4,300円",
        "choice2": "4,500円",
        "choice3": "4,700円",
        "choice4": "4,900円",
        "choice5": "5,100円",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 75,
        "shikenId": 2,
        "parentNo": 35,
        "no": 1,
        "answerType": 1,
        "question1": "■ある会社で、部署ごとに備品の発注状況をまとめています。<br> <br> 【備品の発注状況】<br> <img src='/img/ss35.png' class='c-img'/>",
        "question2": "部署Ｄが発注した備品の合計金額はいくらと推測できるか。",
        "question3": "",
        "answer": "3",
        "score": 1,
        "choice1": "1,420,000円",
        "choice2": "1,620,000円",
        "choice3": "1,820,000円",
        "choice4": "2,020,000円",
        "choice5": "2,220,000円",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 76,
        "shikenId": 3,
        "parentNo": 1,
        "no": 1,
        "answerType": 1,
        "question1": "式中の□に入る数値として正しいものを次の選択肢の中から１つ選び、ボタンをクリックしてください。",
        "question2": "",
        "question3": "9878－□＝5863",
        "answer": "3",
        "score": 1,
        "choice1": "3015",
        "choice2": "3515",
        "choice3": "4015",
        "choice4": "4515",
        "choice5": "5015",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 77,
        "shikenId": 3,
        "parentNo": 2,
        "no": 1,
        "answerType": 1,
        "question1": "式中の□に入る数値として正しいものを次の選択肢の中から１つ選び、ボタンをクリックしてください。",
        "question2": "",
        "question3": "361÷□＝266÷2÷7",
        "answer": "2",
        "score": 1,
        "choice1": "14",
        "choice2": "19",
        "choice3": "24",
        "choice4": "29",
        "choice5": "34",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 78,
        "shikenId": 3,
        "parentNo": 3,
        "no": 1,
        "answerType": 1,
        "question1": "式中の□に入る数値として正しいものを次の選択肢の中から１つ選び、ボタンをクリックしてください。",
        "question2": "",
        "question3": "(35＋□)÷4＝12×5",
        "answer": "3",
        "score": 1,
        "choice1": "105",
        "choice2": "110",
        "choice3": "205",
        "choice4": "210",
        "choice5": "305",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 79,
        "shikenId": 3,
        "parentNo": 4,
        "no": 1,
        "answerType": 1,
        "question1": "式中の□に入る数値として正しいものを次の選択肢の中から１つ選び、ボタンをクリックしてください。",
        "question2": "",
        "question3": "□×13＋□×8＝84（□には同じ値が入ります）",
        "answer": "2",
        "score": 1,
        "choice1": "2",
        "choice2": "4",
        "choice3": "6",
        "choice4": "8",
        "choice5": "10",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 80,
        "shikenId": 3,
        "parentNo": 5,
        "no": 1,
        "answerType": 1,
        "question1": "式中の□に入る数値として正しいものを次の選択肢の中から１つ選び、ボタンをクリックしてください。",
        "question2": "",
        "question3": "□％＝3/20",
        "answer": "4",
        "score": 1,
        "choice1": "6",
        "choice2": "9",
        "choice3": "12",
        "choice4": "15",
        "choice5": "18",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 81,
        "shikenId": 3,
        "parentNo": 6,
        "no": 1,
        "answerType": 1,
        "question1": "式中の□に入る数値として正しいものを次の選択肢の中から１つ選び、ボタンをクリックしてください。",
        "question2": "",
        "question3": "2/3×13－□＝2×4",
        "answer": "5",
        "score": 1,
        "choice1": "6/7",
        "choice2": "5/6",
        "choice3": "4/5",
        "choice4": "3/4",
        "choice5": "2/3",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 82,
        "shikenId": 3,
        "parentNo": 7,
        "no": 1,
        "answerType": 1,
        "question1": "式中の□に入る数値として正しいものを次の選択肢の中から１つ選び、ボタンをクリックしてください。",
        "question2": "",
        "question3": "□×2/5＝0.2×70",
        "answer": "1",
        "score": 1,
        "choice1": "35",
        "choice2": "40",
        "choice3": "45",
        "choice4": "50",
        "choice5": "55",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 83,
        "shikenId": 3,
        "parentNo": 8,
        "no": 1,
        "answerType": 1,
        "question1": "式中の□に入る数値として正しいものを次の選択肢の中から１つ選び、ボタンをクリックしてください。",
        "question2": "",
        "question3": "10－□×3/2＝0.5×8",
        "answer": "4",
        "score": 1,
        "choice1": "2.5",
        "choice2": "3",
        "choice3": "3.5",
        "choice4": "4",
        "choice5": "4.5",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 84,
        "shikenId": 3,
        "parentNo": 9,
        "no": 1,
        "answerType": 1,
        "question1": "式中の□に入る数値として正しいものを次の選択肢の中から１つ選び、ボタンをクリックしてください。",
        "question2": "",
        "question3": "350×1/2×7＝□×1/4",
        "answer": "4",
        "score": 1,
        "choice1": "4300",
        "choice2": "4500",
        "choice3": "4700",
        "choice4": "4900",
        "choice5": "5100",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 85,
        "shikenId": 3,
        "parentNo": 10,
        "no": 1,
        "answerType": 1,
        "question1": "式中の□に入る数値として正しいものを次の選択肢の中から１つ選び、ボタンをクリックしてください。",
        "question2": "",
        "question3": "0.4×0.8＝0.02×□",
        "answer": "1",
        "score": 1,
        "choice1": "16",
        "choice2": "19",
        "choice3": "22",
        "choice4": "25",
        "choice5": "28",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 86,
        "shikenId": 3,
        "parentNo": 11,
        "no": 1,
        "answerType": 1,
        "question1": "式中の□に入る数値として正しいものを次の選択肢の中から１つ選び、ボタンをクリックしてください。",
        "question2": "",
        "question3": "5.3÷0.2＋□＝16÷0.2",
        "answer": "3",
        "score": 1,
        "choice1": "51.5",
        "choice2": "52.5",
        "choice3": "53.5",
        "choice4": "54.5",
        "choice5": "55.5",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 87,
        "shikenId": 3,
        "parentNo": 12,
        "no": 1,
        "answerType": 1,
        "question1": "式中の□に入る数値として正しいものを次の選択肢の中から１つ選び、ボタンをクリックしてください。",
        "question2": "",
        "question3": "□÷2＋15＝550－400",
        "answer": "1",
        "score": 1,
        "choice1": "270",
        "choice2": "272",
        "choice3": "274",
        "choice4": "276",
        "choice5": "278",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 88,
        "shikenId": 3,
        "parentNo": 13,
        "no": 1,
        "answerType": 1,
        "question1": "式中の□に入る数値として正しいものを次の選択肢の中から１つ選び、ボタンをクリックしてください。",
        "question2": "",
        "question3": "5/13＋2/3＝2－□",
        "answer": "2",
        "score": 1,
        "choice1": "39/41",
        "choice2": "37/39",
        "choice3": "35/37",
        "choice4": "33/35",
        "choice5": "31/33",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 89,
        "shikenId": 3,
        "parentNo": 14,
        "no": 1,
        "answerType": 1,
        "question1": "式中の□に入る数値として正しいものを次の選択肢の中から１つ選び、ボタンをクリックしてください。",
        "question2": "",
        "question3": "□の65％＝585",
        "answer": "5",
        "score": 1,
        "choice1": "700",
        "choice2": "750",
        "choice3": "800",
        "choice4": "850",
        "choice5": "900",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 90,
        "shikenId": 3,
        "parentNo": 15,
        "no": 1,
        "answerType": 1,
        "question1": "式中の□に入る数値として正しいものを次の選択肢の中から１つ選び、ボタンをクリックしてください。",
        "question2": "",
        "question3": "□×1/3＝2700÷□（□には同じ値が入ります）",
        "answer": "3",
        "score": 1,
        "choice1": "0.9",
        "choice2": "9",
        "choice3": "90",
        "choice4": "900",
        "choice5": "9000",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 91,
        "shikenId": 3,
        "parentNo": 16,
        "no": 1,
        "answerType": 1,
        "question1": "式中の□に入る数値として正しいものを次の選択肢の中から１つ選び、ボタンをクリックしてください。",
        "question2": "",
        "question3": "5/4＋2/3×□＝6.5×1/2",
        "answer": "2",
        "score": 1,
        "choice1": "1/3",
        "choice2": "3",
        "choice3": "0.3",
        "choice4": "0.03",
        "choice5": "30",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 92,
        "shikenId": 3,
        "parentNo": 17,
        "no": 1,
        "answerType": 1,
        "question1": "式中の□に入る数値として正しいものを次の選択肢の中から１つ選び、ボタンをクリックしてください。",
        "question2": "",
        "question3": "8×3/2＝6×□",
        "answer": "3",
        "score": 1,
        "choice1": "1/2",
        "choice2": "0.2",
        "choice3": "2",
        "choice4": "20",
        "choice5": "0.02",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 93,
        "shikenId": 3,
        "parentNo": 18,
        "no": 1,
        "answerType": 1,
        "question1": "式中の□に入る数値として正しいものを次の選択肢の中から１つ選び、ボタンをクリックしてください。",
        "question2": "",
        "question3": "4.5÷0.3＝□÷30",
        "answer": "2",
        "score": 1,
        "choice1": "150",
        "choice2": "450",
        "choice3": "4.5",
        "choice4": "45",
        "choice5": "1.5",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 94,
        "shikenId": 3,
        "parentNo": 19,
        "no": 1,
        "answerType": 1,
        "question1": "式中の□に入る数値として正しいものを次の選択肢の中から１つ選び、ボタンをクリックしてください。",
        "question2": "",
        "question3": "□＋3/4×12＝25",
        "answer": "2",
        "score": 1,
        "choice1": "8",
        "choice2": "16",
        "choice3": "24",
        "choice4": "32",
        "choice5": "40",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 95,
        "shikenId": 3,
        "parentNo": 20,
        "no": 1,
        "answerType": 1,
        "question1": "式中の□に入る数値として正しいものを次の選択肢の中から１つ選び、ボタンをクリックしてください。",
        "question2": "",
        "question3": "72×1/3＝□÷1/3",
        "answer": "3",
        "score": 1,
        "choice1": "4",
        "choice2": "6",
        "choice3": "8",
        "choice4": "10",
        "choice5": "12",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 96,
        "shikenId": 3,
        "parentNo": 21,
        "no": 1,
        "answerType": 1,
        "question1": "式中の□に入る数値として正しいものを次の選択肢の中から１つ選び、ボタンをクリックしてください。",
        "question2": "",
        "question3": "□÷1/2－17＝107＋51×2",
        "answer": "4",
        "score": 1,
        "choice1": "107",
        "choice2": "109",
        "choice3": "111",
        "choice4": "113",
        "choice5": "115",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 97,
        "shikenId": 3,
        "parentNo": 22,
        "no": 1,
        "answerType": 1,
        "question1": "式中の□に入る数値として正しいものを次の選択肢の中から１つ選び、ボタンをクリックしてください。",
        "question2": "",
        "question3": "7/15＋1/3＝□％",
        "answer": "5",
        "score": 1,
        "choice1": "60",
        "choice2": "65",
        "choice3": "70",
        "choice4": "75",
        "choice5": "80",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 98,
        "shikenId": 3,
        "parentNo": 23,
        "no": 1,
        "answerType": 1,
        "question1": "式中の□に入る数値として正しいものを次の選択肢の中から１つ選び、ボタンをクリックしてください。",
        "question2": "",
        "question3": "52－□＝□×9.4（□には同じ値が入ります）",
        "answer": "2",
        "score": 1,
        "choice1": "4.5",
        "choice2": "5",
        "choice3": "5.5",
        "choice4": "6",
        "choice5": "6.5",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 99,
        "shikenId": 3,
        "parentNo": 24,
        "no": 1,
        "answerType": 1,
        "question1": "式中の□に入る数値として正しいものを次の選択肢の中から１つ選び、ボタンをクリックしてください。",
        "question2": "",
        "question3": "45÷0.3＝□×1/3",
        "answer": "1",
        "score": 1,
        "choice1": "450",
        "choice2": "600",
        "choice3": "750",
        "choice4": "900",
        "choice5": "1050",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 100,
        "shikenId": 3,
        "parentNo": 25,
        "no": 1,
        "answerType": 1,
        "question1": "式中の□に入る数値として正しいものを次の選択肢の中から１つ選び、ボタンをクリックしてください。",
        "question2": "",
        "question3": "555÷3/2＝□×0.125",
        "answer": "3",
        "score": 1,
        "choice1": "29.6",
        "choice2": "296",
        "choice3": "2960",
        "choice4": "29600",
        "choice5": "296000",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 101,
        "shikenId": 3,
        "parentNo": 26,
        "no": 1,
        "answerType": 1,
        "question1": "式中の□に入る数値として正しいものを次の選択肢の中から１つ選び、ボタンをクリックしてください。",
        "question2": "",
        "question3": "1500÷3.75－150＝□×50",
        "answer": "3",
        "score": 1,
        "choice1": "0.05",
        "choice2": "0.5",
        "choice3": "5",
        "choice4": "50",
        "choice5": "500",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 102,
        "shikenId": 3,
        "parentNo": 27,
        "no": 1,
        "answerType": 1,
        "question1": "式中の□に入る数値として正しいものを次の選択肢の中から１つ選び、ボタンをクリックしてください。",
        "question2": "",
        "question3": "2÷2/5＝□÷2/3",
        "answer": "2",
        "score": 1,
        "choice1": "5/3",
        "choice2": "10/3",
        "choice3": "20/3",
        "choice4": "3/5",
        "choice5": "6/5",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 103,
        "shikenId": 3,
        "parentNo": 28,
        "no": 1,
        "answerType": 1,
        "question1": "式中の□に入る数値として正しいものを次の選択肢の中から１つ選び、ボタンをクリックしてください。",
        "question2": "",
        "question3": "1/10＋□＝3/5",
        "answer": "1",
        "score": 1,
        "choice1": "1/2",
        "choice2": "1/3",
        "choice3": "1/4",
        "choice4": "1/5",
        "choice5": "1/10",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 104,
        "shikenId": 3,
        "parentNo": 29,
        "no": 1,
        "answerType": 1,
        "question1": "式中の□に入る数値として正しいものを次の選択肢の中から１つ選び、ボタンをクリックしてください。",
        "question2": "",
        "question3": "(50－□)×(30＋5)＝155×7",
        "answer": "2",
        "score": 1,
        "choice1": "9",
        "choice2": "19",
        "choice3": "29",
        "choice4": "39",
        "choice5": "49",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 105,
        "shikenId": 3,
        "parentNo": 30,
        "no": 1,
        "answerType": 1,
        "question1": "式中の□に入る数値として正しいものを次の選択肢の中から１つ選び、ボタンをクリックしてください。",
        "question2": "",
        "question3": "□×4＝27×(50－14)",
        "answer": "4",
        "score": 1,
        "choice1": "3",
        "choice2": "9",
        "choice3": "81",
        "choice4": "243",
        "choice5": "729",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 106,
        "shikenId": 3,
        "parentNo": 31,
        "no": 1,
        "answerType": 1,
        "question1": "式中の□に入る数値として正しいものを次の選択肢の中から１つ選び、ボタンをクリックしてください。",
        "question2": "",
        "question3": "(5.6＋17.4)÷2＝□×2",
        "answer": "3",
        "score": 1,
        "choice1": "5.25",
        "choice2": "5.5",
        "choice3": "5.75",
        "choice4": "6",
        "choice5": "6.25",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 107,
        "shikenId": 3,
        "parentNo": 32,
        "no": 1,
        "answerType": 1,
        "question1": "式中の□に入る数値として正しいものを次の選択肢の中から１つ選び、ボタンをクリックしてください。",
        "question2": "",
        "question3": "54÷□＝126－108",
        "answer": "1",
        "score": 1,
        "choice1": "3",
        "choice2": "6",
        "choice3": "9",
        "choice4": "12",
        "choice5": "15",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 108,
        "shikenId": 3,
        "parentNo": 33,
        "no": 1,
        "answerType": 1,
        "question1": "式中の□に入る数値として正しいものを次の選択肢の中から１つ選び、ボタンをクリックしてください。",
        "question2": "",
        "question3": "(9.7－3.5)×5＝□×5－3",
        "answer": "2",
        "score": 1,
        "choice1": "6.2",
        "choice2": "6.8",
        "choice3": "7.2",
        "choice4": "7.8",
        "choice5": "8.2",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 109,
        "shikenId": 3,
        "parentNo": 34,
        "no": 1,
        "answerType": 1,
        "question1": "式中の□に入る数値として正しいものを次の選択肢の中から１つ選び、ボタンをクリックしてください。",
        "question2": "",
        "question3": "67×3＋□＝81×3",
        "answer": "1",
        "score": 1,
        "choice1": "42",
        "choice2": "43",
        "choice3": "44",
        "choice4": "45",
        "choice5": "46",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 110,
        "shikenId": 3,
        "parentNo": 35,
        "no": 1,
        "answerType": 1,
        "question1": "式中の□に入る数値として正しいものを次の選択肢の中から１つ選び、ボタンをクリックしてください。",
        "question2": "",
        "question3": "2.6＋3/10＝580×□",
        "answer": "3",
        "score": 1,
        "choice1": "0.5",
        "choice2": "0.05",
        "choice3": "0.005",
        "choice4": "0.0005",
        "choice5": "0.00005",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 111,
        "shikenId": 3,
        "parentNo": 36,
        "no": 1,
        "answerType": 1,
        "question1": "式中の□に入る数値として正しいものを次の選択肢の中から１つ選び、ボタンをクリックしてください。",
        "question2": "",
        "question3": "□×0.2＝16.2÷□（□には同じ値が入ります）",
        "answer": "2",
        "score": 1,
        "choice1": "6",
        "choice2": "9",
        "choice3": "12",
        "choice4": "15",
        "choice5": "18",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 112,
        "shikenId": 3,
        "parentNo": 37,
        "no": 1,
        "answerType": 1,
        "question1": "式中の□に入る数値として正しいものを次の選択肢の中から１つ選び、ボタンをクリックしてください。",
        "question2": "",
        "question3": "90÷□＝□÷0.1（□には同じ値が入ります）",
        "answer": "3",
        "score": 1,
        "choice1": "0.3",
        "choice2": "0.03",
        "choice3": "3",
        "choice4": "9",
        "choice5": "90",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 113,
        "shikenId": 3,
        "parentNo": 38,
        "no": 1,
        "answerType": 1,
        "question1": "式中の□に入る数値として正しいものを次の選択肢の中から１つ選び、ボタンをクリックしてください。",
        "question2": "",
        "question3": "52×0.4＝□×1/5",
        "answer": "1",
        "score": 1,
        "choice1": "104",
        "choice2": "120",
        "choice3": "124",
        "choice4": "150",
        "choice5": "180",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 114,
        "shikenId": 3,
        "parentNo": 39,
        "no": 1,
        "answerType": 1,
        "question1": "式中の□に入る数値として正しいものを次の選択肢の中から１つ選び、ボタンをクリックしてください。",
        "question2": "",
        "question3": "37×1.5＝□×1/20",
        "answer": "3",
        "score": 1,
        "choice1": "110",
        "choice2": "220",
        "choice3": "1110",
        "choice4": "2220",
        "choice5": "11110",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 115,
        "shikenId": 3,
        "parentNo": 40,
        "no": 1,
        "answerType": 1,
        "question1": "式中の□に入る数値として正しいものを次の選択肢の中から１つ選び、ボタンをクリックしてください。",
        "question2": "",
        "question3": "42÷0.3＝□×2/3",
        "answer": "1",
        "score": 1,
        "choice1": "210",
        "choice2": "270",
        "choice3": "320",
        "choice4": "390",
        "choice5": "430",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 116,
        "shikenId": 3,
        "parentNo": 41,
        "no": 1,
        "answerType": 1,
        "question1": "式中の□に入る数値として正しいものを次の選択肢の中から１つ選び、ボタンをクリックしてください。",
        "question2": "",
        "question3": "77×0.7＝1078÷□",
        "answer": "5",
        "score": 1,
        "choice1": "0.2",
        "choice2": "0.4",
        "choice3": "2",
        "choice4": "4",
        "choice5": "20",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 117,
        "shikenId": 3,
        "parentNo": 42,
        "no": 1,
        "answerType": 1,
        "question1": "式中の□に入る数値として正しいものを次の選択肢の中から１つ選び、ボタンをクリックしてください。",
        "question2": "",
        "question3": "30×0.3－4×0.5＝□×0.1",
        "answer": "1",
        "score": 1,
        "choice1": "70",
        "choice2": "210",
        "choice3": "280",
        "choice4": "350",
        "choice5": "420",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 118,
        "shikenId": 3,
        "parentNo": 43,
        "no": 1,
        "answerType": 1,
        "question1": "式中の□に入る数値として正しいものを次の選択肢の中から１つ選び、ボタンをクリックしてください。",
        "question2": "",
        "question3": "65×(9＋□)＝1024－(57－8)",
        "answer": "3",
        "score": 1,
        "choice1": "2",
        "choice2": "4",
        "choice3": "6",
        "choice4": "8",
        "choice5": "10",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 119,
        "shikenId": 3,
        "parentNo": 44,
        "no": 1,
        "answerType": 1,
        "question1": "式中の□に入る数値として正しいものを次の選択肢の中から１つ選び、ボタンをクリックしてください。",
        "question2": "",
        "question3": "88÷(9－5)＝32＋□－26",
        "answer": "3",
        "score": 1,
        "choice1": "2",
        "choice2": "8",
        "choice3": "16",
        "choice4": "32",
        "choice5": "64",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 120,
        "shikenId": 3,
        "parentNo": 45,
        "no": 1,
        "answerType": 1,
        "question1": "式中の□に入る数値として正しいものを次の選択肢の中から１つ選び、ボタンをクリックしてください。",
        "question2": "",
        "question3": "10÷0.001＝400000×□",
        "answer": "4",
        "score": 1,
        "choice1": "25",
        "choice2": "2.5",
        "choice3": "0.25",
        "choice4": "0.025",
        "choice5": "0.0025",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 121,
        "shikenId": 3,
        "parentNo": 46,
        "no": 1,
        "answerType": 1,
        "question1": "式中の□に入る数値として正しいものを次の選択肢の中から１つ選び、ボタンをクリックしてください。",
        "question2": "",
        "question3": "2.2×(4.1－□)＝8.8÷5",
        "answer": "3",
        "score": 1,
        "choice1": "1.1",
        "choice2": "2.2",
        "choice3": "3.3",
        "choice4": "4.4",
        "choice5": "5.5",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 122,
        "shikenId": 3,
        "parentNo": 47,
        "no": 1,
        "answerType": 1,
        "question1": "式中の□に入る数値として正しいものを次の選択肢の中から１つ選び、ボタンをクリックしてください。",
        "question2": "",
        "question3": "□－40×2＝1/3×45",
        "answer": "3",
        "score": 1,
        "choice1": "105",
        "choice2": "100",
        "choice3": "95",
        "choice4": "90",
        "choice5": "85",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 123,
        "shikenId": 3,
        "parentNo": 48,
        "no": 1,
        "answerType": 1,
        "question1": "式中の□に入る数値として正しいものを次の選択肢の中から１つ選び、ボタンをクリックしてください。",
        "question2": "",
        "question3": "1200の□％＝288",
        "answer": "3",
        "score": 1,
        "choice1": "16",
        "choice2": "20",
        "choice3": "24",
        "choice4": "28",
        "choice5": "32",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 124,
        "shikenId": 3,
        "parentNo": 49,
        "no": 1,
        "answerType": 1,
        "question1": "式中の□に入る数値として正しいものを次の選択肢の中から１つ選び、ボタンをクリックしてください。",
        "question2": "",
        "question3": "4/5＋31/10＝78×□％",
        "answer": "1",
        "score": 1,
        "choice1": "5",
        "choice2": "10",
        "choice3": "15",
        "choice4": "20",
        "choice5": "25",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    },
    {
        "id": 125,
        "shikenId": 3,
        "parentNo": 50,
        "no": 1,
        "answerType": 1,
        "question1": "式中の□に入る数値として正しいものを次の選択肢の中から１つ選び、ボタンをクリックしてください。",
        "question2": "",
        "question3": "(7/3＋5)÷5/6＝35.2×□",
        "answer": "5",
        "score": 1,
        "choice1": "0.05",
        "choice2": "0.1",
        "choice3": "0.15",
        "choice4": "0.2",
        "choice5": "0.25",
        "choice6": "",
        "choice7": "",
        "choice8": "",
        "choice9": "",
        "choice10": ""
    }
]