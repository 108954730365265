import { IBasicUtil } from "@/interfaces/IBasicUtil";
import { ActiveLoader, useLoading } from 'vue-loading-overlay'
import { v4 as uuidv4 } from 'uuid';
import Cookies from 'js-cookie';
const passObj: { [name: string]: string } = { "1": "eh7qWqz8dfgbhZHB9zVsHurEQaBrJpMXkv"}
export class BasicUtil implements IBasicUtil {
  private loading: ActiveLoader | undefined
  private loadingCount = 0;

  public setGradePass(grade: string): void {
    Cookies.set('tac-cbt-spi-' + grade, passObj[grade], { expires: 365 });
  }

  public isAuth(grade: string): boolean {
    const cookie = Cookies.get('tac-cbt-spi-' + grade)
    return cookie !== undefined && cookie == passObj[grade]
  }

  public getQa3() {
    const qas = [
      "202711599",
    ]
    return qas;
  }

  public zentoHanNumber(val: string): string {
    if (val.length == 0 || val == "") return "";
    val = val.replace(/０/g, '0');
    val = val.replace(/１/g, '1');
    val = val.replace(/２/g, '2');
    val = val.replace(/３/g, '3');
    val = val.replace(/４/g, '4');
    val = val.replace(/５/g, '5');
    val = val.replace(/６/g, '6');
    val = val.replace(/７/g, '7');
    val = val.replace(/８/g, '8');
    val = val.replace(/９/g, '9');
    if (/[^\-.0-9]+/.test(val)) return "";
    return val
  }

  public async showLoading() {
    if (this.loadingCount == 0) {
      const $loading = useLoading()
      this.loading = $loading.show({
        color: '#ccc',
        loader: 'spinner',
        width: 64,
        height: 64,
        backgroundColor: '#ffffff',
        opacity: 0.3,
        zIndex: 999,
      });
    }
    this.loadingCount += 1
  }

  public async hideLoading() {
    this.loadingCount -= 1
    if (this.loadingCount == 0) {
      this.loading?.hide()
    }
  }


  public onlyNumberMinus(val: string): string {
    if (val.length == 0 || val == "") return "";
    return val.replace(/(?!^)-|[^0-9]/g, '').replace(/^(-?\d*).*/, '$1');
  }

  public onlyNumberMinusWithoutComma(val: string): string {
    if (val.length == 0 || val == "") return "";
    if (/[^0-9.-]/.test(val) || (val.match(/\./g) || []).length > 1 || (val.match(/-/g) || []).length > 1) return "";
    return val
  }

  public parseHtml(content: string): string {
    const domparser = new DOMParser();
    const htmlDoc = domparser.parseFromString(
      content,
      "text/html"
    );
    // let ret = htmlDoc.body.innerHTML
    // const imgs: HTMLCollection = htmlDoc.getElementsByTagName("img");
    // Array.prototype.forEach.call(imgs, function (item: HTMLImageElement) {
    //   const isNoZoom = item.alt == "nozoom"
    //   const zoomableTag = BasicUtil.getZoomableImageTag(item.src, item.width, isNoZoom)
      
    //   ret = ret.replaceAll(item.outerHTML, zoomableTag)
    // });


    return htmlDoc.body.innerHTML
  }

  public setBasicSetting() {
    window.addEventListener("popstate", this.reload);
  }

  reload () {
    if (confirm("すべての解答がクリアされます。")) {
        location.href = "/"
      }
  }


  public removeBasicSetting() {
    window.onbeforeunload = null
    window.removeEventListener("popstate", this.reload);
  }
  


  public resizeImage(before:string, value:string) {
    const container = document.getElementById('mainStage');
    if (!container) return
      // コンテナ内の全てのimgタグを取得
      const images = container.getElementsByTagName('img');
      // それぞれのimgタグの幅を取得し、変更する
      for (const img of Array.from(images)) {
          // 現在のスタイル幅を取得
          const currentWidth: string | null = img.style.width;

          // 新しい幅を設定（例：+50ピクセル）
          let plus = 0
          const diff = Number(value) - Number(before)
          if (diff > 0) {
            plus = 1.12 ** diff
          } else if (diff < 0) {
            plus = 1 / (1.12 ** -diff)
          } else {
            return
          }
          const newWidth: number = parseInt(currentWidth ? currentWidth : "0", 10) * plus;
          img.style.width = `${newWidth}px`;
      }
  }

  private static getZoomableImageTag(src: string, width: number, isNoZoom: boolean): string {
    if (isNoZoom) {
      return `
      <figure class="imgBox" style="width:${width}px">
          <img
            src="${src}"
            class=""
            width="${width}"
          />
        </figure>`
    }
    const uuidStr = "i" + uuidv4()
    return `
    <figure class="imgBox" style="width:${width}px">
        <img
          src="${src}"
          class=""
          width="${width}"
        />
        <button
          class="btn badge px-4 py-3 bgGry zoomImgBtn"
          data-bs-toggle="modal"
          data-bs-target="#${uuidStr}"
        >
          <span></span>
        </button>
      </figure>

      <!-- Modal -->
      <div
        class="modal fade"
        id="${uuidStr}"
        tabindex="-1"
        aria-labelledby="${uuidStr}Label"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div class="modal-content">
            <div class="modal-body p-5 text-center spPad">
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
              <img
                src="${src}"
                class="p-3"
                alt="img"
                style="width: 100%"
              />
            </div>
          </div>
        </div>
      </div>
`;
  }
}